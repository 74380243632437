<template>
  <div>
    <div class="space-y-24">
      <div
        v-for="pool in stakingPools"
        :key="`${pool.address}-${pool.inputToken.address}`"
        :id="`${pool.address}-${pool.inputToken.address}`"
      >
        <staking-card-content
          v-if="pool.stakableProtocol.includes('pendle')"
          :staking-pool="pool"
          :contract-address="pool.address"
          :input-token-address="pool.inputToken.address"
        ></staking-card-content>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import StakingPool from '@/domains/entities/StakingPool'
  import Token from '@/domains/entities/Token'
  import StakingCardContent from '@/components/StakingCardContent.vue'

  export default Vue.extend({
    components: {
      StakingCardContent,
    },
    data() {
      return {
        stakingPools: StakingPool.query()
          .where('inputToken.address', this.$route.params.inputTokenAddress)
          .toArray()
          .sort((a, b) => (a.disabled === b.disabled ? 0 : a.disabled ? 1 : -1)),
      }
    },
    computed: {
      inputToken() {
        const token = Token.query().find(this.$route.params.inputTokenAddress)
        return token.address ? token : null
      },
    },
  })
</script>
