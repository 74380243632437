<template>
  <div>
    <pp-card-body>
      <pp-simple-data>
        <template #left>
          <pp-tab-container>
            <pp-tab
              @click="mode = 'Stake'"
              :active="mode === 'Stake'"
              data-test="stake"
              :disabled="stakeDisabled"
            >
              Stake
            </pp-tab>
            <pp-tab @click="mode = 'Unstake'" :active="mode === 'Unstake'" data-test="unstake">
              Unstake
            </pp-tab>
          </pp-tab-container>
        </template>
      </pp-simple-data>
    </pp-card-body>

    <div
      class="border-t border-b flex flex-wrap divide-y sm:divide-y-0 sm:divide-x"
      :key="`stats-area-${formSuccess}`"
    >
      <div class="w-full sm:w-1/3 px-2">
        <pp-stat header="APR">
          <template v-slot:content>
            <div v-if="stakingPool.disabled">0.000%</div>

            <div v-else>
              <div v-if="aprState.is('resolved')">
                <pp-formatted-number :value="totalApr" data-test="apr"></pp-formatted-number>
                %
                <pp-tooltip-trigger data-test="tooltip">
                  <div v-for="reward in rewards" :key="reward.origin">
                    <pp-formatted-percent
                      :data-test="`${reward.origin}-apr`"
                      :value="reward.apr"
                      class="text-p-blue-800"
                    ></pp-formatted-percent>
                    {{ reward.origin }}
                  </div>
                </pp-tooltip-trigger>
              </div>

              <pp-skeleton-loader
                v-else-if="aprState.is('loading')"
                class="h-8 w-16"
              ></pp-skeleton-loader>

              <div v-else-if="aprState.is('rejected')">
                <pp-btn @click="fetchAprs" color="p-red" variant="accent">Retry</pp-btn>
              </div>
            </div>
          </template>
        </pp-stat>
      </div>

      <div class="w-full sm:w-1/3 px-2">
        <pp-stat header="Total staked">
          <template v-slot:content>
            <div v-if="stakingPool.disabled">-</div>
            <div v-else>
              <div v-if="totalStakedState.is('resolved')" class="flex items-center">
                <img
                  class="h-5 inline-block mr-2"
                  :src="stakingPool.inputToken.img"
                  :alt="stakingPool.inputToken.name"
                  :title="stakingPool.inputToken.name"
                />

                <span data-test="total-staked" v-if="totalStakedAmount < 0.001">
                  <span v-text="`< 0.001`"></span>
                </span>

                <span v-else>
                  <pp-formatted-number
                    :min-decimal="0"
                    :value="totalStakedAmount"
                    data-test="total-staked"
                  ></pp-formatted-number>
                </span>
              </div>

              <pp-skeleton-loader
                v-if="totalStakedState.is('loading')"
                class="h-8 w-16"
              ></pp-skeleton-loader>

              <div v-if="totalStakedState.is('rejected')">
                <pp-btn @click="fetchTotalStaked" color="p-red" variant="accent">Retry</pp-btn>
              </div>
            </div>
          </template>

          <template v-slot:subcontent>
            <div v-if="!stakingPool.disabled">
              <pp-skeleton-loader v-if="totalStakedState.is('loading')"></pp-skeleton-loader>

              <pp-formatted-price
                v-if="totalStakedState.is('resolved')"
                :currency="totalStakedState.response.valuation.currency"
                :value="totalStakedState.response.valuation.amount"
                data-test="total-staked-usd"
              ></pp-formatted-price>

              <div v-if="totalStakedState.is('rejected')" class="text-p-red-500">
                Unable to fetch data
              </div>
            </div>
          </template>
        </pp-stat>
      </div>

      <div class="w-full sm:w-1/3 px-2">
        <pp-stat header="Your stake">
          <template v-slot:content>
            <div v-if="userStaked.is('resolved')" class="flex items-center">
              <img
                class="h-5 inline-block mr-2"
                :src="stakingPool.inputToken.img"
                :alt="stakingPool.inputToken.name"
                :title="stakingPool.inputToken.name"
              />

              <span
                v-if="
                  userStaked.response.formattedAmount() > 0 &&
                  userStaked.response.formattedAmount() < 0.001
                "
                v-text="'< 0.001'"
                data-test="your-stake"
              ></span>
              <pp-formatted-number
                v-else
                :min-decimal="0"
                :value="userStaked.response.formattedAmount()"
                data-test="your-stake"
              ></pp-formatted-number>
            </div>

            <pp-skeleton-loader
              v-if="userStaked.is('loading')"
              class="h-8 w-16"
            ></pp-skeleton-loader>

            <div v-if="userStaked.is('rejected')">
              <pp-btn @click="fetchUserStaked" color="p-red" variant="accent">Retry</pp-btn>
            </div>
          </template>

          <template v-slot:subcontent>
            <pp-skeleton-loader v-if="userStaked.is('loading')"></pp-skeleton-loader>

            <pp-token-amount-valuation
              v-if="userStaked.is('resolved')"
              :token-amount="userStaked.response"
              data-test="your-stake-usd"
            ></pp-token-amount-valuation>

            <div v-if="userStaked.is('rejected')" class="text-p-red-500">Unable to fetch data</div>
          </template>
        </pp-stat>
      </div>
    </div>

    <pp-card-body :key="`form-area-${formSuccess}`">
      <div class="px-4">
        <pp-fade-transition hide-on-leave>
          <StakingForm
            v-if="mode === 'Stake'"
            :staking-pool="stakingPool"
            @success="handleSuccess"
          />

          <UnstakingForm
            v-else-if="mode === 'Unstake'"
            :staking-pool="stakingPool"
            @success="handleUnstakeSuccess"
          />
        </pp-fade-transition>
      </div>

      <v-easy-dialog v-model="showV2Prompt">
        <template v-slot="{ deactivate }">
          <pp-card class="shadow-none">
            <pp-card-title>
              <div class="text-right">
                <pp-btn @click="deactivate" icon variant="text" size="sm">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <title>Close dialog</title>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </pp-btn>
              </div>
              <img class="h-52 mx-auto" :src="require('@/assets/images/starry_window.png')" />
              <div class="text-xl font-bold text-center mt-2">
                Lock your PENDLE to boost your liquidity provision APY!
              </div>
            </pp-card-title>
            <pp-card-body>
              <div class="px-4 text-sm">
                <p>In V2, you can lock PENDLE and receive vePENDLE. vePENDLE has 3 benefits:</p>
                <ol class="list-decimal pl-6">
                  <li>
                    Boosted LP rewards of
                    <span class="font-bold">up to 2.5x</span>
                  </li>
                  <li>
                    Deciding where
                    <span class="font-bold">incentives</span>
                    go
                  </li>
                  <li>
                    A share in
                    <span class="font-bold">protocol fees and pool fees</span>
                  </li>
                </ol>
              </div>
            </pp-card-body>
            <pp-card-actions class="text-center space-x-2 mt-4">
              <pp-btn
                tag="a"
                target="_blank"
                href="https://app.pendle.finance/vependle/overview"
                class="font-bold w-44"
              >
                Go to vePENDLE
              </pp-btn>
              <pp-btn
                tag="a"
                target="_blank"
                href="https://docs.pendle.finance/Governance/vePENDLE"
                class="font-bold w-44"
                variant="text"
              >
                Learn More
              </pp-btn>
            </pp-card-actions>
          </pp-card>
        </template>
      </v-easy-dialog>
    </pp-card-body>
  </div>
</template>

<script>
  import Vue from 'vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import StakingForm from '@/components/forms/StakingForm.vue'
  import UnstakingForm from '@/components/forms/UnstakingForm.vue'
  import PendleMarket from '@/domains/entities/PendleMarket'
  import StakingPool from '@/domains/entities/StakingPool'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import { sdkChainId } from '@/app-config/network'
  import VEasyDialog from 'v-easy-dialog'

  export default Vue.extend({
    components: {
      StakingForm,
      UnstakingForm,
      VEasyDialog,
    },
    props: {
      stakingPool: { type: Object, required: true },
    },
    data() {
      return {
        userStaked: createState(),
        formSuccess: 0,
        mode: 'Stake',
        aprState: createState({ response: [] }),
        totalStakedState: createState({
          response: {
            amount: null,
            valuation: {
              amount: '0',
              currency: 'USD',
            },
          },
        }),
        showV2Prompt: false,
      }
    },
    watch: {
      currentMinute: {
        handler() {
          this.fetchAprs()
          this.fetchTotalStaked()
        },
        immediate: true,
      },
    },
    methods: {
      TokenAmount,
      fetchUserStaked() {
        new PromiseHandler(
          () =>
            this.stakingPool
              .contract(this.$store.getters['wallet/identity'])
              .balanceOf(this.$store.state.wallet.address),
          this.userStaked
        ).execute()
      },
      fetchSwapFeeApr() {
        if (!this.market) return Promise.resolve([{ origin: 'Swap fee', apr: '0' }])

        return new Promise((resolve, reject) => {
          this.market
            .contract(this.$store.getters['wallet/identity'])
            .getSwapFeeApr()
            .then((response) => {
              resolve([{ origin: 'Swap fee', apr: response }])
            })
            .catch((error) => {
              reject(error)
            })
        })
      },
      fetchAprs() {
        new PromiseHandler(
          () =>
            Promise.all([
              StakingPool.query()
                .find(
                  `${this.stakingPool.address}-${this.stakingPool.inputToken.address}-${this.stakingPool.inputToken.expiry}`
                )
                .contract(this.$store.getters['wallet/identity'])
                .rewardAprs(this.market?.token),
              this.fetchSwapFeeApr(),
            ]),
          this.aprState
        ).execute()
      },
      fetchTotalStaked() {
        new PromiseHandler(
          () =>
            StakingPool.query()
              .find(
                `${this.stakingPool.address}-${this.stakingPool.inputToken.address}-${this.stakingPool.inputToken.expiry}`
              )
              .contract(this.$store.getters['wallet/identity'])
              .getTotalStaked(),

          this.totalStakedState
        ).execute()
      },
      handleSuccess() {
        this.formSuccess++
        this.fetchUserStaked()
        this.fetchAprs()
        this.fetchTotalStaked()
      },
      handleUnstakeSuccess() {
        this.showV2Prompt = true
        this.handleSuccess()
      },
    },
    computed: {
      currentMinute() {
        return this.$app.state.time.getMinutes()
      },
      rewards() {
        return this.aprState.response
          .flat()
          .map((reward) => ({ ...reward, apr: 100 * reward.apr }))
          .filter((reward) => reward.apr > 0)
      },
      totalApr() {
        return this.rewards.reduce((a, b) => a + b.apr, 0)
      },
      totalStakedAmount() {
        return this.totalStakedState.response.amount
          ? this.totalStakedState.response.amount.formattedAmount()
          : 0
      },
      market() {
        return PendleMarket.query().find(this.stakingPool.inputToken.address)
      },
      stakeDisabled() {
        const isMainnet = sdkChainId === 1
        if (isMainnet) {
          // disable staking for everything except Pendle, which doesn't have market
          return Boolean(this.market)
        }
        return this.market?.statusIs('inactive')
      },
    },
    created() {
      this.fetchUserStaked()
      this.mode = this.stakingPool.disabled || this.stakeDisabled ? 'Unstake' : 'Stake'
    },
  })
</script>
