<template>
  <pp-form @submit.prevent="handleSubmit">
    <template v-slot:default="{ disabled }">
      <pp-token-amount-input
        v-model="inputAmount"
        :address="stakingPool.inputToken.address"
        :disabled="stakingPool.disabled"
        :rules="[(val) => val > 0 || 'Amount must be larger than 0']"
      ></pp-token-amount-input>

      <pp-token-approve-container
        class="mt-5"
        :spending-amount="tokenAmount"
        :spender-address="stakingPool.address"
        :disabled="stakingPool.disabled"
      >
        <template v-slot:default="{ approved }">
          <div class="mt-2">
            <pp-btn
              v-if="approved"
              class="flex w-full"
              size="xl"
              data-test="btn"
              :disabled="disabled || stakingPool.disabled"
              :loading="formSubmission.state === 'loading'"
            >
              Stake
            </pp-btn>
          </div>
        </template>
      </pp-token-approve-container>
    </template>
  </pp-form>
</template>

<script>
  import Vue from 'vue'
  import PromiseHandler, { createState } from '@/domains/PromiseHandler'
  import TokenAmount from '@/domains/entities/TokenAmount'
  import { generateEtherscanTxUrl, formatNumber } from '@/assets/helpers'

  export default Vue.extend({
    props: {
      stakingPool: { type: Object, required: true },
    },
    data() {
      return {
        formSubmission: createState(),
        inputAmount: '',
      }
    },
    computed: {
      tokenAmount() {
        return new TokenAmount(this.stakingPool.inputToken, this.inputAmount || 0, false)
      },
    },
    methods: {
      handleSubmit() {
        new PromiseHandler(
          () =>
            this.stakingPool
              .contract(this.$store.getters['wallet/identity'])
              .stake(this.tokenAmount),
          this.formSubmission
        )
          .execute()
          .then((response) => {
            this.$notification.success({
              title: 'Stake',
              text: `Staked ${formatNumber(this.inputAmount)} PendleLP (${
                this.stakingPool.inputToken.name
              })`,
              action: {
                url: generateEtherscanTxUrl(response.transactionHash),
                urlText: 'View on explorer',
              },
            })

            this.$emit('success', response)
          })
          .catch((error) => {
            this.$notification.error({
              title: 'Stake',
              text: `Unable to stake ${this.stakingPool.inputToken.name}`,
              action: {
                urlText: 'View on explorer',
                url: error.receipt?.transactionHash
                  ? generateEtherscanTxUrl(error.receipt.transactionHash)
                  : '',
              },
            })
          })
      },
    },
  })
</script>
